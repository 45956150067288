import React, { useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import NewsCard from "../reusableComponents/NewsCard";
import Heading from "../../../components/shared/Heading";
import Fetcher from "../../../components/shared/Fetcher";
import VerticalCategoriesBlock from "../reusableComponents/VerticalCategoriesBlock";
import VerticalTopNewsBlock from "./VerticalTopNewsBlock";
import Button from "../../../components/shared/Button";

import { useCurrentLanguage } from "../../../hooks/useCurrentLanguage";
import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import { IMAGE_KEYS } from "../../../utilities/constants";
import { unixDateConvertor } from "../../../utilities/dateTimeUtils";
import newsService from "../../service/newsService";
import { useQueryParamByName } from "../../../hooks/useQueryParamByName";
import NewsListSkeleton from "../reusableComponents/skeletons/NewsListSkeleton";

const NewsListComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [language] = useCurrentLanguage();
  const { newsState, newsDispatch } = useNewsPageContext();

  const currentPageQueryString = useQueryParamByName("current-page");
  const pageSizeQueryString = useQueryParamByName("page-size");

  const fetchAllNews = useCallback(
    async () =>
      await newsService.getNews({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedAllNews = useCallback(
    (response) => {
      newsDispatch({ type: "allNewsFetch", response: response });
    },
    [newsDispatch]
  );

  return (
    <Container>
      <Row>
        <Col md="8">
          <Heading tag="h4" className={"news-title"} title="More News" />
          <Fetcher
            action={fetchAllNews}
            onLoad={handleOnLoadFetchedAllNews}
            loadingComponent={<NewsListSkeleton />}
          >
            {newsState?.data?.map((news) => (
              <NewsCard
                key={news?.id}
                image={
                  news?.imageUrls?.[language]?.[IMAGE_KEYS.COVER_IMAGE_GET]
                }
                title={news?.title?.[language]}
                categories={news?.categoryIds}
                createdDate={unixDateConvertor(news?.createdAt)}
                onClick={() => navigate(`/news/${news?.id}`)}
                language={language}
              />
            ))}
          </Fetcher>
          {newsState?.totalPages > newsState?.currentPage && (
            <div className={"d-flex justify-content-center"}>
              <Button
                text={"Show More"}
                onClick={() => {
                  const pageNumber = newsState?.currentPage + 1;
                  navigate(`${location.pathname}?current-page=${pageNumber}`);
                }}
              />
            </div>
          )}
        </Col>
        <Col md="4">
          <div className="news-list-side-panel">
            <VerticalCategoriesBlock
              categories={newsState?.categories}
              language={language}
            />
            <VerticalTopNewsBlock />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsListComponent;
