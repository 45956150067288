//UOS
import Extension_EC_UoS_REGULATION from "./UOS/Extension_EC UoS REGULATION.docx";
import Academic_Misconduct_UoS_REGULATION from "./UOS/Academic Misconduct UoS REGULATION.docx";
import Academic_Misconduct_UoS_STUDENT_GUIDE from "./UOS/Academic Misconduct UoS STUDENT GUIDE.docx";
import Extension_EC_UoS_STUDENT_GUIDE from "./UOS/Extension_EC UoS STUDENT GUIDE.docx";
import Extenuating_Circumstances_Form_UoS from "./UOS/Extenuating Circumstances Form UoS.docx";
import Contract_for_paid_educational_services_UOS from "./UOS/Contract for paid educational services_UOS.docx";

//DMU
import Academic_Misconduct_DMU_REGULATION from "./DMU/Academic Misconduct DMU  REGULATION.docx";
import Academic_Misconduct_DMU_STUDENT_GUIDE from "./DMU/Academic Misconduct DMU  STUDENT GUIDE.docx";
import Deferrals_DMU_REGULATION from "./DMU/Deferrals DMU REGULATION.docx";
import Defarral_Request_Form_DMU from "./DMU/Deferral request form DMU.docx";
import Extension_Deferral_DMU_STUDENT_GUIDE from "./DMU/Extension_Deferral_DMU STUDENT GUIDE.docx";
import Extenuating_Circumstances_Form_DMU from "./DMU/Extension request form DMU.docx";
import Contract_for_paid_educational_services_DMU from "./DMU/Contract_for_paid_educational_services_DMU.docx";

export const DMU_Regulations = [
  {
    title: "Regulation on Academic Offences and Bad Academic Practice",
    document: Academic_Misconduct_DMU_REGULATION,
    documentName: "Academic Misconduct DMU  REGULATION.docx",
  },
  {
    title: "Student Guide on Academic Offences and Bad Academic Practice",
    document: Academic_Misconduct_DMU_STUDENT_GUIDE,
    documentName: "Academic Misconduct DMU STUDENT GUIDE.docx",
  },
  {
    title:
      "Regulation on Deferral of Assessment on Grounds of Extenuating Circumtances",
    document: Deferrals_DMU_REGULATION,
    documentName: "Deferrals DMU REGULATION.docx",
  },
  {
    title: "Deferral Request Form",
    document: Defarral_Request_Form_DMU,
    documentName: "Deferral request form DMU.docx",
  },
  {
    title:
      "Student Guide on Extensions to Coursework Deadlines and Deferral of Assessment",
    document: Extension_Deferral_DMU_STUDENT_GUIDE,
    documentName: "Extension_Deferral_DMU STUDENT GUIDE.docx",
  },
  {
    title: "Extension Request Form",
    document: Extenuating_Circumstances_Form_DMU,
    documentName: "Extension request form DMU.docx",
  },
  {
    title: "Contract For Paid Educational Services",
    document: Contract_for_paid_educational_services_DMU,
    documentName: "Contract_for_paid_educational_services_DMU.docx",
  },
];

export const UOS_Regulations = [
  {
    title: "Academic Misconduct Regulations",
    document: Academic_Misconduct_UoS_REGULATION,
    documentName: "Academic Misconduct UoS REGULATION.docx",
  },
  {
    title: "Academic Integrity and Academic Misconduct Student Guide",
    document: Academic_Misconduct_UoS_STUDENT_GUIDE,
    documentName: "Academic Misconduct UoS STUDENT GUIDE.docx",
  },
  {
    title:
      "Regulations Governing Extension of Assessment Deadlines and Considiration of Extenuating Circumstances",
    document: Extension_EC_UoS_REGULATION,
    documentName: "Extension_EC UoS REGULATION.docx",
  },
  {
    title:
      "Student Guide on Extension of Assessment Deadlines and Consideration of Extenuating Circumstances",
    document: Extension_EC_UoS_STUDENT_GUIDE,
    documentName: "Extension_EC UoS STUDENT GUIDE.docx",
  },
  {
    title: "Extenuating Circumstances Form",
    document: Extenuating_Circumstances_Form_UoS,
    documentName: "Extenuating_Circumstances_Form_UoS.docx",
  },
  {
    title: "Contract For Paid Educational Services",
    document: Contract_for_paid_educational_services_UOS,
    documentName: "Contract for paid educational services_UOS.docx",
  },
];
