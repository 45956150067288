import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .sample-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-radius: 8px;
    transition: all 0.2s ease;
    overflow: hidden;
    margin-bottom: 15px;
    padding: 15px 20px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

    .sample-title {
      font-weight: 600;
      color: ${colors.black1};

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    .download-button {
      font-size: 14px;
      color: #fff;
      border: none;
      background: ${colors.gr_bg};
      text-align: center;
      border-radius: 8px;
      font-size: 14px;
      padding: 4px 20px;
      cursor: pointer;

      &:hover {
        background: ${colors.gr_bg2};
      }

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
`;
