import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { Styles } from "./styles/iconBox.js";
import { colors } from "./common/element/elements.js";

const IconBox = ({ data }) => {
  return (
    <Styles>
      <section className="icon-box-area">
        <Container>
          <Row>
            {data?.map((data, i) => (
              <Col md="4" key={i}>
                <motion.div
                  className="full-icon-box"
                  initial={{ y: -100 }}
                  animate={{ y: 0 }}
                  transition={{ type: "spring", stiffness: 100 }}
                >
                  <div className="icon-box">
                    <div className={data.uniqClass}>
                      {data.iconPath ? (
                        <img
                          src={data.iconPath}
                          alt="icon"
                          width={80}
                          color={colors.bg1}
                        />
                      ) : (
                        <i className={data.boxIcon} />
                      )}
                    </div>
                    <h6>{data.title}</h6>
                    <p>{data.subTitle}</p>
                  </div>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default IconBox;
