import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const HeaderItem = ({ title, link, isDropdown }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <li className={`nav-item ${isDropdown ? "dropdown" : ""}`}>
      <Link
        className={`nav-link ${
          location.pathname.split("/")?.[1] === link.split("/")?.[1]
            ? "active"
            : ""
        } ${isDropdown ? "dropdown-toggle" : ""}`}
        to={link}
        onClick={() =>
          ReactGA.event({ category: "Header Menu", action: title })
        }
      >
        {t(title)}
      </Link>
      {isDropdown ? (
        <ul className="dropdown list-unstyled">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname.split("/")?.[1] === link.split("/")?.[1]
                  ? "active"
                  : ""
              }`}
              to={link}
              onClick={() =>
                ReactGA.event({ category: "Header Menu", action: title })
              }
            >
              {t(title)}
            </Link>
          </li>
        </ul>
      ) : null}
    </li>
  );
};

export default HeaderItem;
