import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addYears } from "date-fns";

import FileDropDownComponent from "../../components/shared/FileDropDownComponent";
import FormWithInputAndLabel from "../../components/shared/FormWithInputAndLabel";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import TooltipInformationComponent from "../../components/shared/TooltipInformationComponent";

import { useStatusContext } from "../../hooks/contextHooks/useStatusContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import {
  COURSE_ENUM,
  PRE_UNIVERSITY_COURSES_READABLE,
  SCHOLARSHIP_TYPES_ENUM,
} from "../../utilities/constants";
import {
  cleanUpObject,
  getObjectPropertyName,
} from "../../utilities/objectUtils";
import { appendToFormData } from "../../utilities/formDataUtils";
import userService from "../../services/userService";

import { Styles } from "./styles/apply.js";
import { useTranslation } from "react-i18next";
import Button from "../../components/shared/Button";
import AlertComponent from "../../components/shared/AlertComponent";
import moment from "moment";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("First name field is required"),
  lastName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Last name field is required"),
  middleName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Middle name field is required"),
  dateOfBirth: yup
    .date()
    .max(addYears(new Date(), -14), "You must be at least 14 years old.")
    .required("Date of birth field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email field is required"),
  emailCheck: yup
    .string()
    .required("Please reenter your email")
    .test("email-match", "Emails do not match", function (value) {
      const email = this.parent.email;
      return value === email;
    }),
  phoneNumber: yup
    .string()
    .length(13, "Phone number must be exactly 12 numbers long")
    .required("Phone number field is required"),
  phoneNumberCheck: yup
    .string()
    .required("Please reenter your phone number")
    .test("phone-match", "Phone numbers do not match", function (value) {
      const phoneNumber = this.parent.phoneNumber;
      return value === phoneNumber;
    }),
  passportSeriesAndNumber: yup
    .string()
    .matches(
      /^[A-Z]{2}\d{7}$/,
      "Invalid passport number format. E.g.(AD1312233)"
    )
    .required("Passport number field is required"),
  preUniversityCourses: yup.array().optional(),
});

const StudentsApplicationForm = () => {
  const [confirmation, setConfirmation] = useState(false);
  const [confirmError, setConfirmError] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const { onErrorStatus } = useStatusContext();
  const [onFetch, isLoading] = useBeManualFetcher();
  const formRef = useRef();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const programme = searchParams.get("programme") ?? state?.facultyType;

  const [exists, setExists] = useState();
  const [isPlanning, setIsPlanning] = useState(false);
  const [scholarshipType, setScholarshipType] = useState(
    SCHOLARSHIP_TYPES_ENUM.TUITION_FEE
  );

  const [englishTestDate, setEnglishTestDate] = useState("1 sentabr 2024 yil");

  useEffect(() => {
    if (scholarshipType === SCHOLARSHIP_TYPES_ENUM.TUITION_FEE) {
      setEnglishTestDate(t("15 september, 2024"));
    } else {
      setEnglishTestDate(t("15 august, 2024"));
    }
  }, [scholarshipType]);
  const [files, setFiles] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [passportCopy, setPassportCopy] = useState([]);
  const [englishFile, setEnglishFile] = useState([]);
  // const [personalStateFile, setPersonalStateFile] = useState([]);
  const [preUniCourses, setPreUniCourses] = useState();

  const onFormSubmit = async (form) => {
    const {
      firstName,
      lastName,
      middleName,
      dateOfBirth,
      passportSeriesAndNumber,
      email,
      emailCheck,
      phoneNumber,
      phoneNumberCheck,
      isExists,
      isPlanning,
      planningDate,
      city,
      district,
      street,
      postalCode,
      instruction,
      hasDisability,
    } = form;

    let newFormData = new FormData();

    if (emailCheck !== email)
      return onErrorStatus({
        title: "Validation error",
        message: "Email address you entered does not match",
      });

    if (phoneNumberCheck !== phoneNumber)
      return onErrorStatus({
        title: "Validation error",
        message: "Phone number you entered does not match",
      });
    const filteredObject = cleanUpObject({
      firstName,
      lastName,
      middleName,
      dateOfBirth: moment(dateOfBirth).format("MM-DD-YYYY"),
      email,
      phoneNumber,
      passportSeriesAndNumber: passportSeriesAndNumber.toUpperCase(),
      facultyType: programme,
      city,
      district,
      street,
      postalCode,
      instruction,
      HasDisability: hasDisability,
    });

    appendToFormData(newFormData, filteredObject);

    if (programme === COURSE_ENUM.PRE_FOUNDATION) {
      return await onFetch({
        action: () => userService?.createStudent(newFormData),
        onLoad: () => formRef.current.reset(),
        successMessage: "Application submitted successfully",
      });
    }

    if (programme === COURSE_ENUM.PRE_UNIVERSITY) {
      preUniCourses
        ?.map((course) => course.value)
        .forEach((course, index) =>
          appendToFormData(newFormData, {
            preUniversityCourseType: course,
          })
        );

      return await onFetch({
        action: () => userService?.createStudent(newFormData),
        onLoad: () => formRef.current.reset(),
        successMessage: "Application submitted successfully",
      });
    }

    appendToFormData(newFormData, {
      Passport: passportCopy?.[0],
      Photo: photo?.[0],
      feetype: getObjectPropertyName(SCHOLARSHIP_TYPES_ENUM, scholarshipType),
    });

    if (isExists === "yes") {
      appendToFormData(newFormData, {
        HasEnglishLanguageProficiencyEvidence: true,
        EnglishProficiencyEvidenceCertificate: englishFile?.[0],
      });
    }
    if (isExists === "no") {
      newFormData.append("HasEnglishLanguageProficiencyEvidence", false);

      if (isPlanning === "yes") {
        appendToFormData(newFormData, {
          "[EnglishProficiencyEvidenceTest][Planning]": true,
          "[EnglishProficiencyEvidenceTest][ExamDate]": planningDate,
        });
      }
    }
    if (files?.length > 0) {
      const mergedFiles = [...files];

      mergedFiles.forEach((file) => {
        newFormData.append(`files`, file);
      });
    }

    if (confirmation) {
      return await onFetch({
        action: () => userService?.createStudent(newFormData),
        onLoad: () => {
          formRef.current.reset();
          setFiles([]);
          setPhoto([]);
          setPassportCopy([]);
          setEnglishFile([]);
          // setPersonalStateFile([]);
          AlertComponent({
            title:
              "You have successfully submitted your application to the PTU!",
            text: "Our Admission team will send you e-mail as soon as possible.",
            icon: "success",
          });
        },
      });
    } else {
      setConfirmError("Input field is required to fill*");
    }
  };
  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox
          title={t("Student Application Form")}
          image="/assets/images/banner-5.jpg"
        />
        <section className="registration-area application-form">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>{t("Apply Now")}</h3>
                    <div className="registration-note">
                      {t(
                        "If you have any question, please feel free to contact us through Telegram bot?"
                      )}{" "}
                      <a href="https://t.me/ptuuz_bot">{t("Click Here")}</a>
                    </div>
                  </div>
                  <form
                    id="form_registration"
                    className="form"
                    onSubmit={handleSubmit(onFormSubmit)}
                    ref={formRef}
                  >
                    <Row>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"First Name"}
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          id="firstName"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="5" lg="4">
                        <FormWithInputAndLabel
                          label={"Last Name"}
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          id="lastName"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Middle Name"}
                          type="text"
                          placeholder="Middle Name"
                          name="middleName"
                          id="middleName"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Date of Birth"}
                          placeholder={"mm/dd/yyyy"}
                          onfocus="(this.type='date')"
                          onblur="(this.type='text')"
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Email Address"}
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Re-Enter Email Address"}
                          type="email"
                          placeholder="Email Address"
                          name="emailCheck"
                          id="emailCheck"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Phone Number"}
                          type="text"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          defaultValue={"+998"}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Re-Enter Phone Number"}
                          type="text"
                          placeholder="Phone number"
                          name="phoneNumberCheck"
                          id="phoneNumberCheck"
                          defaultValue={"+998"}
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Passport Series And Number"}
                          type="text"
                          placeholder="Passport Series And Number"
                          name="passportSeriesAndNumber"
                          id="passportSeriesAndNumber"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"City"}
                          type="text"
                          placeholder="City"
                          name="city"
                          id="city"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"District"}
                          type="text"
                          placeholder="District"
                          name="district"
                          id="district"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Street"}
                          type="text"
                          placeholder="Street"
                          name="street"
                          id="street"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Home Number"}
                          type="text"
                          placeholder="Home Number"
                          name="instruction"
                          id="instruction"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Postal Code"}
                          type="number"
                          placeholder="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          register={register}
                          errors={errors}
                        />
                      </Col>
                      {programme === COURSE_ENUM.PRE_UNIVERSITY ? (
                        <Col md="12">
                          <div className="form-control">
                            <label htmlFor="pre-uni">
                              {t("Courses To Take")}
                            </label>
                            <Select
                              placeholder={t(
                                "Select courses you want to take..."
                              )}
                              value={preUniCourses}
                              onChange={(e) => setPreUniCourses(e)}
                              className="select"
                              name="preUniversityCourses"
                              options={Object.keys(
                                PRE_UNIVERSITY_COURSES_READABLE
                              ).map((course) => {
                                return {
                                  label: t(
                                    PRE_UNIVERSITY_COURSES_READABLE?.[course]
                                  ),
                                  value: course,
                                };
                              })}
                              isMulti
                            />
                            {errors?.["preUniversityCourses"] &&
                              preUniCourses?.length < 1 && (
                                <p className="invalid">
                                  {errors?.["preUniversityCourses"]?.message}
                                </p>
                              )}
                          </div>
                        </Col>
                      ) : programme !== COURSE_ENUM.PRE_FOUNDATION ? (
                        <>
                          <Col md="4">
                            <div className="form-control">
                              <label htmlFor="scholarship">
                                {t("Scholarship Type")}
                              </label>
                              <select
                                id="scholarship"
                                value={scholarshipType}
                                onChange={(e) =>
                                  setScholarshipType(e.target.value)
                                }
                              >
                                {Object.values(SCHOLARSHIP_TYPES_ENUM).map(
                                  (type) => (
                                    <option value={type} key={type}>
                                      {t(type)}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                          <Col md="12" lg="12">
                            <div className="form-control">
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="form-title mr-3">
                                  {t(
                                    "Please, disclose if you have any disability"
                                  )}
                                </div>
                                <TooltipInformationComponent
                                  componentToShow={
                                    <div className="photo-information">
                                      <p className="mb-2">
                                        {t(
                                          "Declare if you need the special health conditions. We need this information in order to provide you with appropriate support"
                                        )}
                                      </p>
                                    </div>
                                  }
                                />
                              </div>
                              <div className="form-control custom-radio mb-2">
                                <div className="mr-5">
                                  <label htmlFor="hasDisability">
                                    {t("Yes, I have disability")}
                                  </label>
                                  <input
                                    type="radio"
                                    name="hasDisability"
                                    id="hasDisability"
                                    className="radio-input"
                                    value={true}
                                    {...register("hasDisability")}
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="noDisability"
                                    className="mr-2"
                                  >
                                    {t("No, I don`t have disability")}
                                  </label>
                                  <input
                                    type="radio"
                                    name="hasDisability"
                                    id="noDisability"
                                    className="radio-input"
                                    value={false}
                                    {...register("hasDisability")}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md="12" lg="12">
                            <div className="form-title">
                              {t(
                                "Do you have English language proficiency evidence?"
                              )}
                            </div>
                            <div className="form-control custom-radio mb-2">
                              <div className="mr-5">
                                <label htmlFor="isExistsYes">{t("Yes")}</label>
                                <input
                                  type="radio"
                                  name="isExists"
                                  id="isExistsYes"
                                  className="radio-input"
                                  value={"yes"}
                                  onClick={(e) => setExists(e.target.value)}
                                  {...register("isExists")}
                                />
                              </div>
                              <div>
                                <label htmlFor="no" className="mr-2">
                                  {t("No")}
                                </label>
                                <input
                                  type="radio"
                                  name="isExists"
                                  id="no"
                                  className="radio-input"
                                  value={"no"}
                                  onClick={(e) => setExists(e.target.value)}
                                  {...register("isExists")}
                                />
                              </div>
                            </div>
                          </Col>
                          {exists === "yes" && (
                            <Col lg="12">
                              <div className="form-control">
                                <label htmlFor={"englishFile"}>
                                  {t(
                                    "Upload a scanned copy of your English Proficiency Evidence"
                                  )}
                                </label>
                                <FileDropDownComponent
                                  files={englishFile}
                                  setFiles={setEnglishFile}
                                />
                              </div>
                            </Col>
                          )}
                          {exists === "no" && (
                            <Col lg="12">
                              <div className="form-title">
                                {t(
                                  "Are you planning to take an English Proficiency test, e.g. IELTS?"
                                )}
                              </div>
                              <div className="form-control custom-radio mb-2">
                                <div className="mr-5">
                                  <label htmlFor="planningYes">
                                    {t("Yes")}
                                  </label>
                                  <input
                                    type="radio"
                                    name="isPlanning"
                                    id="planningYes"
                                    value={"yes"}
                                    className="radio-input"
                                    onClick={(e) =>
                                      setIsPlanning(e.target.value)
                                    }
                                    {...register("isPlanning")}
                                  />
                                </div>
                                <div>
                                  <label htmlFor="planningNo" className="mr-2">
                                    {t("No")}
                                  </label>
                                  <input
                                    type="radio"
                                    name="isPlanning"
                                    id="planningNo"
                                    value={"no"}
                                    className="radio-input"
                                    onClick={(e) =>
                                      setIsPlanning(e.target.value)
                                    }
                                    {...register("isPlanning")}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                          {exists === "no" && isPlanning === "yes" && (
                            <Col lg="12">
                              <FormWithInputAndLabel
                                label={
                                  "When are you planning to take an English Proficiency test, e.g. IELTS"
                                }
                                type="date"
                                name="planningDate"
                                id="planningDate"
                                register={register}
                                errors={errors}
                              />
                            </Col>
                          )}
                          {exists === "no" && isPlanning === "no" && (
                            <Col lg="12">
                              <div className="form-control my-2">
                                <label>
                                  {t(
                                    "You need to send your English proficiency test results to the admissions on:"
                                  )}{" "}
                                  <strong>{t(englishTestDate)}</strong>
                                </label>
                              </div>
                            </Col>
                          )}
                          <Col md="6" lg="6">
                            <div className="form-control">
                              <label htmlFor={"passportCopy"}>
                                {t("Copy of the Passport")}
                              </label>
                              <FileDropDownComponent
                                files={passportCopy}
                                setFiles={setPassportCopy}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="form-control">
                              <div className="d-flex justify-content-between align-items-center">
                                <label htmlFor={"photo"}>
                                  {t("Personal Photo (3.5x4.5)")}
                                </label>
                                <TooltipInformationComponent
                                  componentToShow={
                                    <div className="photo-information">
                                      <p className="mb-2">
                                        {t(
                                          "Official photo with white background is acceptable."
                                        )}
                                      </p>
                                    </div>
                                  }
                                />
                              </div>
                              <FileDropDownComponent
                                files={photo}
                                setFiles={setPhoto}
                              />
                            </div>
                          </Col>
                          {/* <Col md="4" lg="4">
                            <div className="form-control">
                              <div className="d-flex justify-content-between align-items-center">
                                <label htmlFor={"personalState"}>
                                  {t("Personal Statement")}
                                </label>
                                <TooltipInformationComponent
                                  componentToShow={
                                    <div className="personal-state-information">
                                      <p className="mb-2">
                                        {t(
                                          "Please, take into account these questions as guidance to write your personal statements. Follow them and answer the questions. The personal statement should consist of 200-300 words."
                                        )}
                                      </p>
                                      <ul className="px-4">
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "Why have you chosen this course?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "What excites you about the subject?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "Is your previous or current study relevant to the course?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "Do you have any relevant work experience?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "Which of your life experience would you be able to discuss?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "What achievements are you proud of?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "What skills do you have that make you perfect for the course?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "What makes this course appealing to you?"
                                          )}
                                        </li>
                                        <li className="personal-state-information-list-item">
                                          {t(
                                            "What plans and ambitions do you have for your future career?"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                />
                              </div>
                              <FileDropDownComponent
                                files={personalStateFile}
                                setFiles={setPersonalStateFile}
                              />
                            </div>
                          </Col> */}
                          <Col lg="12">
                            <div className="form-control">
                              <label htmlFor={"id"}>
                                {t(
                                  "Upload your Secondary education certificate (School/College/Lyceum diploma or attestate)*, transcript and others"
                                )}
                              </label>
                              <FileDropDownComponent
                                files={files}
                                setFiles={setFiles}
                                multiple
                                buttonText={"Select files"}
                              />
                            </div>
                          </Col>
                        </>
                      ) : null}
                      <Col md="12">
                        <div className={`custom-checkbox mb-4`}>
                          <label
                            htmlFor={"confirmation"}
                            className="font-weight-bold"
                          >
                            {t(
                              "I consent to process my personal data for the admission purpose."
                            )}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="checkbox"
                            name="confirmation"
                            id="confirmation"
                            className="ml-2"
                            style={{
                              width: "20px",
                            }}
                            checked={confirmation}
                            onChange={() => setConfirmation(!confirmation)}
                          />
                          {!confirmation && confirmError && (
                            <p className="invalid">{confirmError}</p>
                          )}
                        </div>
                      </Col>
                      <Col lg="12" className="d-flex justify-content-center">
                        <Button
                          type={"submit"}
                          className="px-5 mt-3"
                          text={"Submit"}
                          disabled={isLoading}
                          isLoading={isLoading}
                          eventName={"student_app_submit"}
                        />
                      </Col>
                    </Row>
                  </form>
                  <div className="have_account-btn text-center">
                    <p>
                      {t("Do you looking for a job vacancies?")}{" "}
                      <Link to="/apply-employees">{t("Apply here")}</Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default StudentsApplicationForm;
