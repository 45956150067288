import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";

export const createStudent = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.createStudent,
  };

  const apiUser = await backendHttpRequest({
    resourceObject,
    body,
    isFormData: true,
  });

  return apiUser;
};

export const createEmployee = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.createEmployee,
  };

  const apiUser = await backendHttpRequest({
    resourceObject,
    body,
    isFormData: true,
  });

  return apiUser;
};
