import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .international-area {
    padding: 40px 0;

    .data-title {
      text-transform: uppercase;
      font-size: 16px;
      color: #444;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .data-desc {
      font-size: 15px;
      line-height: 28px;
      color: #666;
      margin-bottom: 16px;
    }

    .living {
      margin-bottom: 32px;
    }

    .living-map {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      img {
        max-width: 100%;
      }
    }
  }

  .guide-link {
    display: flex;
    justify-content: center;
    a {
      font-size: 15px;
      color: #fff;
      background: ${colors.gr_bg};
      display: inline-block;
      font-weight: 500;
      text-align: center;
      padding: 14px;
      border: none;
      border-radius: 8px;
      margin-top: 20px;

      &:hover {
        background: ${colors.gr_bg2};
      }

      @media (max-width: 575px) {
        font-size: 13px;
        padding: 12px;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    .international-area {
      padding: 24px 0;
    }
  }
`;
