import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import VacancyItem from "../../components/career/VacancyItem";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";

import EnglishData from "../../data/careers/careers.json";
import RussianData from "../../data/careers/careers-rus.json";
import UzbekData from "../../data/careers/careers-uz.json";
import vacancyData from "../../data/vacancy/vacancy.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { Link, useNavigate } from "react-router-dom";

import { Styles } from "./styles/careers.js";

const Careers = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const navigate = useNavigate();
  return (
    <Styles>
      <div className="main-wrapper careers-page">
        <BreadcrumbBox title="Vacancies" image="/assets/images/faq-2.jpg" />
        <section className="strategy-area">
          <Container>
            <Row>
              <Col md="12">
                <p className="strategy-desc">{data.description}</p>
              </Col>
              <Col md="12">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={"Open Vacancies"}
                />
                {vacancyData.map((vacancy) => (
                  <VacancyItem
                    key={vacancy.id}
                    deadline={vacancy?.deadline}
                    title={vacancy.jobTitle}
                    buttonsToRender={[
                      {
                        title: "View",
                        onClick: () =>
                          navigate(`/job-description/${vacancy.id}`),
                      },
                      {
                        title: "Apply Now",
                        onClick: () =>
                          navigate(`/employees-apply-form/${vacancy.id}`),
                      },
                    ]}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default Careers;
