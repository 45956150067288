import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

export const AboutSection = ({ data }) => {
  return (
    <section id="about" className="about">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <div className="title-wrapper">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={data.title}
                />
              </div>
              {data.descriptions.map((desc, i) => (
                <p className="description" key={i}>
                  {desc}
                </p>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
