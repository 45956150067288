import React from "react";

import { Styles } from "./styles/contact.js";

const GoogleMap = () => {
  return (
    <Styles>
      <div className="google-map-area">
        <iframe
          src="https:////www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.0306743438!2d69.28960637676468!3d41.329279312585825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef4ca887aaaab%3A0x712812a77f4a35f3!2suzpharmagency!5e0!3m2!1sen!2s!4v1659507444782!5m2!1sen!2s"
          width="100%"
          height="600"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        />
      </div>
    </Styles>
  );
};

export default GoogleMap;
