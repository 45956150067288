import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const HeaderItemWithDropdown = ({ title, navItems, link }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <li className={`nav-item`}>
      <Link className={`nav-link dropdown-toggle`} to={link}>
        {t(title)} <i className="las la-angle-down" />
      </Link>
      <ul className="dropdown list-unstyled">
        {navItems?.map((navItem, i) => (
          <li key={i} className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname.split("/")?.[1] ===
                navItem.link.split("/")?.[1]
                  ? "active"
                  : ""
              }`}
              to={navItem.link}
              onClick={() =>
                ReactGA.event({
                  category: "Header Menu",
                  action: navItem.title,
                })
              }
            >
              {t(navItem.title)}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default HeaderItemWithDropdown;
