import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import MobileMenu from "./common/MobileMenu";
import Preheader from "../layout/preheader/Preheader";
import HeaderItem from "./HeaderItem";
import { Styles } from "./styles/header.js";
import AnnouncementBar from "../layout/announcement/AnnouncementBar";
import HeaderItemWithDropdown from "./HeaderItemWithDropdown";

import AnnouncementData from "../data/announcement/anouncement.json";
import AnnouncementDataUz from "../data/announcement/anouncement-uz.json";
import AnnouncementDataRu from "../data/announcement/anouncement-ru.json";
import { useLanguageDetector } from "../hooks/useLanguageDetector.js";

const Header = ({ showAfter }) => {
  const [announcementData] = useLanguageDetector(
    AnnouncementData,
    AnnouncementDataRu,
    AnnouncementDataUz
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".logo-area2");

      if (showAfter) {
        if (window.scrollY > 0) stickyMenu.classList.remove("d-none");
        if (window.scrollY > 160) {
          stickyMenu.classList.add("sticky");
          stickyMenu.classList.remove("d-none");
        } else {
          stickyMenu.classList.add("d-none");
          stickyMenu.classList.remove("sticky");
        }
      }
      if (!showAfter) {
        stickyMenu.classList.remove("d-none");
        if (window.scrollY > 5) {
          stickyMenu.classList.add("sticky");
        } else {
          stickyMenu.classList.remove("sticky");
        }
      }
    });
  }, [showAfter]);

  return (
    <Styles>
      <Preheader showAfter={showAfter} />
      <div className="new-design">
        <AnnouncementBar data={{ ...announcementData[2] }} />
        <div className={`logo-area2 z-index-2`}>
          <Container>
            <Row className="align-items-center">
              <Col md="2">
                <div className="logo">
                  <Link to={"/"}>
                    <img src={"/assets/images/logo-white.png"} alt="PTU logo" />
                  </Link>
                </div>
              </Col>
              <Col md="10">
                <div className="menu-box d-flex justify-content-end">
                  <ul className="nav menu-nav">
                    <HeaderItem title={"Study"} link="/study" />
                    <HeaderItem title={"Apply"} link="/apply-students" />
                    <HeaderItemWithDropdown
                      title={"Students"}
                      navItems={[
                        { title: "Student Life", link: "/student-life" },
                        {
                          title: "Student Support Service",
                          link: "/student-support-service",
                        },
                        {
                          title:
                            "Student Information and Support Section – PTU Assists",
                          link: "/student-information-support",
                        },
                        {
                          title: "Social Engagement - Clubs",
                          link: "/social",
                        },
                        {
                          title: "International",
                          link: "/international",
                        },
                        {
                          title: "Tuition Fees",
                          link: "/tuition-fees",
                        },
                        {
                          title: "Academic Regulations",
                          link: "/academic-regulations",
                        },
                      ]}
                    />
                    <HeaderItem title={"News"} link="/news" />
                    <HeaderItem title={"Conference"} link="/conference" />
                    {/* <HeaderItemWithDropdown
                      title={"Conference"}
                      link="/conference"
                      navItems={[
                        {
                          title: "About",
                          link: "/about-conference",
                        },
                        {
                          title: "Program",
                          link: "/conference-program",
                        },
                        {
                          title: "Call For Abstract",
                          link: "/conference-call-for-abstract",
                        },
                      ]}
                    /> */}
                    <HeaderItemWithDropdown
                      title={"About Us"}
                      link="/about"
                      navItems={[
                        { title: "Strategy", link: "/strategy" },
                        { title: "Partners", link: "/partners" },
                        { title: "Our Team", link: "/team" },
                        { title: "Vacancies", link: "/vacancies" },
                        { title: "Contact Us", link: "/contact" },
                      ]}
                    />
                  </ul>
                </div>
                <div className="d-none"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {!showAfter && <MobileMenu />}
    </Styles>
  );
};

export default Header;
