import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import LanguageDropdownComponent from "../../components/shared/LanguageDropdownComponent";
import SocialMediaComponent from "../../components/shared/SocialMediaComponent";
import { colors } from "../../components/common/element/elements";
import { Icon } from "@iconify/react";

const Preheader = ({ showAfter }) => {
  const { t } = useTranslation();

  return (
    <section className={`top-bar2 ${showAfter ? "d-none" : ""}`}>
      <Container>
        <Row>
          <Col xl="8" lg="7">
            <div className="bar-left">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <Icon
                    color={colors.bg2}
                    icon="ph:phone-duotone"
                    fontSize={24}
                    className="mr-1"
                  />
                  <a href="tel:+998958338181">+998 (95) 8338181</a>
                </li>
                <li className="list-inline-item">
                  <Icon
                    color={colors.bg2}
                    icon="ph:envelope-simple-duotone"
                    fontSize={24}
                    className="mr-1"
                  />
                  <a href="mailto:info@pharmatechuni.uz">
                    info@pharmatechuni.uz
                  </a>
                </li>
                <li className="list-inline-item">
                  <Icon
                    color={colors.bg2}
                    icon="ph:map-pin-duotone"
                    fontSize={24}
                    className="mr-1"
                  />
                  <a
                    href={`https://yandex.ru/maps/?ll=69.291001,41.329240&pt=69.291001,41.329240&z=18&l=map`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{t("1A, Chingiz Aytmatov st., Tashkent")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            xl="4"
            lg="5"
            className="d-flex align-items-center justify-content-end"
          >
            <div className="bar-right d-flex align-items-center">
              <LanguageDropdownComponent />
              <SocialMediaComponent />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Preheader;
