import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/student-support-service";
import EnglishData from "../../data/student-support-service/student-support-service.json";
import RussianData from "../../data/student-support-service/student-support-service-ru.json";
import UzbekData from "../../data/student-support-service/student-support-service-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";

const StudentSupportService = () => {
  const [studentSupportServiceData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper student-support-service-page">
      <BreadcrumbBox
        title="Student Support Service"
        image="/assets/images/student-1.jpg"
      />
      <Styles>
        <section className="student-support-service-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="student-support-service-content">
                  {studentSupportServiceData?.paragraphs?.map((par, index) => (
                    <p key={index} className="par">
                      {par}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentSupportService;
