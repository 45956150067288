import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/student-information-support";
import EnglishData from "../../data/student-information-support/student-information-support.json";
import RussianData from "../../data/student-information-support/student-information-support-ru.json";
import UzbekData from "../../data/student-information-support/student-information-support-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";

const StudentInformationSupport = () => {
  const [studentInformationSupportData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper student-information-support-page">
      <BreadcrumbBox
        title="Student Information and Support Section – PTU Assists"
        image="/assets/images/ptu-assists-1.jpg"
      />
      <Styles>
        <section className="student-information-support-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="student-information-support-content">
                  {studentInformationSupportData?.paragraphs?.map(
                    (par, index) => (
                      <p key={index} className="par">
                        {par}
                      </p>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentInformationSupport;
