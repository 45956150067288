import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { DURATION_ENUM } from "../../utilities/constants";

const EmploymentInputsComponent = ({
  id,
  job,
  state,
  setState,
  handleEmploymentRemove,
  handleUpdateEmployment,
  showDeleteButton,
}) => {
  const [startDate, setStartDate] = useState(
    job?.startDate ? job.startDate : ""
  );
  const [endDate, setEndDate] = useState(job?.endDate ? job.endDate : "");
  const [employer, setEmployer] = useState(job?.employer ? job.employer : "");
  const [position, setPosition] = useState(job?.position ? job?.position : "");
  const [duties, setDuites] = useState(job?.duties ? job?.duties : "");
  const [supervisorName, setSupervisorName] = useState(
    job?.supervisor?.name ? job?.supervisor?.name : ""
  );
  const [supervisorPhoneNumber, setSupervisorPhoneNumber] = useState(
    job?.supervisor?.phoneNumber ? job?.supervisor?.phoneNumber : "+998"
  );
  const [isPresent, setIsPresent] = useState(false);

  useEffect(() => {
    const inputFields = {
      id,
      startDate: startDate,
      endDate: endDate,
      employer: employer,
      position: position,
      duties: duties,
      supervisor: {
        name: supervisorName,
        phoneNumber: supervisorPhoneNumber,
      },
      duration: isPresent ? DURATION_ENUM.TILL_NOW : DURATION_ENUM.FINISHED,
    };

    handleUpdateEmployment(id, inputFields);
    return;
  }, [
    startDate,
    endDate,
    employer,
    position,
    duties,
    supervisorName,
    supervisorPhoneNumber,
    isPresent,
  ]);

  return (
    <>
      <Col md="5">
        <FormWithInputAndLabel
          label={"Start Date"}
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e)}
          required
        />
      </Col>
      <Col md="7">
        <Row>
          {!isPresent && (
            <Col lg="8">
              <FormWithInputAndLabel
                label={"End Date"}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e)}
                required
              />
            </Col>
          )}
          <Col lg="4">
            {isPresent && (
              <div className="form-control mb-2">
                <label>End Date</label>
              </div>
            )}
            <div className={`${isPresent ? "" : "mt-5"} custom-checkbox`}>
              <input
                id="1"
                type="checkbox"
                onClick={() => setIsPresent((prev) => !prev)}
              />
              <label htmlFor={"1"}>Present</label>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Name of Organization"}
          type="text"
          placeholder={"Name of Organization"}
          name="employer"
          id="employer"
          value={employer}
          onChange={(e) => setEmployer(e)}
          required
        />
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Position in Organization"}
          type="text"
          placeholder={"Position"}
          name="position"
          id="position"
          value={position}
          onChange={(e) => setPosition(e)}
          required
        />
      </Col>
      <Col md="12">
        <FormWithInputAndLabel
          label={
            "Summary of the main duties and responsibilities in the Organization"
          }
          type="text"
          placeholder={"Enter shortly about main duties and responsibilities"}
          name="duties"
          id="duties"
          value={duties}
          onChange={(e) => setDuites(e)}
          required
        />
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Name of the Supervisor"}
          type="text"
          placeholder={"Name of the Supervisor"}
          name="supervisorName"
          id="supervisorName"
          value={supervisorName}
          onChange={(e) => setSupervisorName(e)}
          required
        />
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Contact Number/ Email address of Supervisor"}
          type="text"
          placeholder={"Contact Number or Email address"}
          name="supervisorPhoneNumber"
          id="supervisorPhoneNumber"
          value={supervisorPhoneNumber}
          onChange={(e) => setSupervisorPhoneNumber(e)}
          required
        />
      </Col>
      {showDeleteButton && (
        <Col md="12" className="d-flex justify-content-end">
          <Button
            text={"Remove"}
            iconName={solid("trash")}
            onClick={() => handleEmploymentRemove(id, state, setState)}
          />
        </Col>
      )}
    </>
  );
};

export default EmploymentInputsComponent;
