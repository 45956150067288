import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .conference-page {
    .breadcrumb {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 60px 0;
      position: relative;
      height: 620px;
      top: -1px;

      @media (max-width: 1199px) {
        height: 550px;
      }

      @media (max-width: 992px) {
        height: 455px;
      }

      @media (max-width: 767px) {
        height: 270px;
      }

      @media (max-width: 500px) {
        height: 220px;
      }

      &:before {
        position: absolute;
        content: "";
        background: #2c0c0f;
        opacity: 0.4;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .breadcrumb-box {
        position: relative;
        border-radius: 8px;
        margin-top: 175px;
        z-index: 2;
        width: fit-content;
        padding: 10px;
        border-radius: 12px;
        backdrop-filter: blur(2px);

        @media (max-width: 1199px) {
          margin-top: 100px;
        }

        @media (max-width: 992px) {
          margin-top: 50px;
        }

        @media (max-width: 500px) {
          margin-top: 30px;
        }

        h2.breadcrumb-title {
          color: #ffffff;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 5px;
          max-width: 650px;

          @media (max-width: 767px) {
            font-size: 20px;
          }

          @media (max-width: 500px) {
            font-size: 16px;
          }
        }

        .year {
          color: ${colors.bg2};
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 10px;

          @media (max-width: 767px) {
            font-size: 20px;
          }

          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
        .device {
          font-style: italic;
          font-weight: 700;
          color: ${colors.bg2};
          font-size: 20px;

          @media (max-width: 500px) {
            font-size: 14px;
          }
        }

        nav {
          ol.breadcrumb {
            display: inline-flex;
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;
            border-radius: 0;

            li.breadcrumb-item {
              a {
                color: ${colors.bg2};
                font-weight: 600;

                &:hover {
                  color: #ffffff;
                }
              }
            }

            li.breadcrumb-item.active {
              color: ${colors.border3};
              font-weight: 600;

              &::before {
                color: ${colors.border3};
              }
            }
          }
        }
      }

      @media (max-width: 1000px) {
        padding: 30px 0;
        margin-top: 0;
      }
    }

    .icon-box {
      .container {
        .row {
          div {
            .full-icon-box {
              .icon-box {
                height: 195px;

                @media (max-width: 991px) {
                  height: 175px;
                }

                @media (max-width: 767px) {
                  height: 100%;
                }

                h6 {
                  font-size: 15px;
                }

                p {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }

    .about {
      .container {
        .row {
          div {
            .main {
              display: flex;
              flex-direction: column;
              padding-top: 80px;
              margin-bottom: 40px;

              .title-wrapper {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .strategy-title {
                  @media (max-width: 767px) {
                    font-size: 18px;
                  }
                }
              }

              .description {
                margin-bottom: 10px;

                @media (max-width: 767px) {
                  margin-bottom: 14px;
                  font-size: 14px;
                }
              }

              @media (max-width: 1199px) {
                padding-top: 70px;
              }

              @media (max-width: 992px) {
                padding-top: 40px;
              }
            }
          }
        }
      }
    }

    .special-issue {
      /* background-color: #f8f7fa; */
      .container {
        .row {
          div {
            .main {
              padding: 40px 0;
              .announcement {
                display: flex;
                align-items: center;
                column-gap: 30px;
                padding: 25px 35px;
                border-radius: 8px;
                margin-bottom: 15px;
                /* margin-top: 30px; */
                border: 2px dashed ${colors.bg2};
                background-color: ${colors.text6};

                img {
                  @media (max-width: 450px) {
                    display: none;
                  }
                }

                .announcement-content {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    .thematic {
      padding: 60px 0;
      background-color: #f8f7fa;
      .container {
        .row {
          div {
            .main {
              display: flex;
              flex-direction: column;

              .strategy-title {
                @media (max-width: 767px) {
                  font-size: 18px;
                }
              }

              .topic-list {
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;

                .topic {
                  background-color: white;
                  margin-bottom: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: start;
                  border-radius: 8px;
                  padding: 10px 15px;
                  column-gap: 10px;
                  font-weight: 600;

                  @media (max-width: 767px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .abstract {
      padding: 60px 0 0;
      /* background-color: #f8f7fa; */
      .container {
        .row {
          div {
            .main {
              /* border: 2px dashed ${colors.bg2}; */
              display: flex;
              flex-direction: column;

              .deadline-list {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .deadline {
                  width: 100%;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  column-gap: 10px;
                  padding: 20px;
                  background: ${colors.text6};
                  border-radius: 8px;
                  box-shadow: 0 0px 7px rgba(0, 0, 0, 0.08);

                  .deadline-block {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    @media (max-width: 767px) {
                      flex-wrap: wrap;
                      row-gap: 10px;
                    }
                  }

                  @media (max-width: 767px) {
                    align-items: start;
                  }
                }

                .apply-button {
                  display: flex;
                  margin-top: 20px;
                }
              }

              .apply {
                color: white;
                display: flex;
                align-items: center;
                color: #fff;
                border: none;
                border-radius: 8px;
                padding: 8px 30px;
                background-color: ${colors.bg1};
                font-weight: 600;
                font-size: 14px;
                white-space: nowrap;

                &:hover {
                  background-color: ${colors.bg2};
                }
              }

              @media (max-width: 767px) {
                font-size: 14px;
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }

    .deadline {
      padding: 60px 0 0;
      /* background-color: #f8f7fa; */
      .container {
        .row {
          div {
            .main {
              /* border: 2px dashed ${colors.bg2}; */

              .list {
                display: flex;
                justify-content: space-around;
                column-gap: 50px;

                .dead {
                  position: relative;
                  width: 290px;

                  .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    row-gap: 10px;
                    text-align: center;

                    .step {
                      display: flex;
                      flex-direction: column;
                      row-gap: 10px;

                      .circle {
                        width: 64px;
                        height: 64px;
                        border-radius: 50%;
                        background-color: ${colors.text6};
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 30px;
                        font-weight: 600;
                        color: ${colors.black1};
                        border: 10px solid ${colors.bg2};
                      }

                      @media (max-width: 767px) {
                        row-gap: 0px;
                      }
                    }

                    .info {
                      display: flex;
                      flex-direction: column;
                      row-gap: 10px;

                      span {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        column-gap: 5px;

                        @media (max-width: 767px) {
                          justify-content: start;
                          margin-bottom: 5px;
                        }
                      }

                      @media (max-width: 767px) {
                        row-gap: 5px;
                        text-align: start;
                      }
                    }

                    @media (max-width: 767px) {
                      flex-direction: row;
                      column-gap: 20px;
                      align-items: end;
                    }
                  }

                  .link {
                    position: absolute;
                    top: 40%;
                    flex-grow: 1;
                    left: calc(-50% + 20px);
                    right: calc(50% + 20px);
                    width: 50%;

                    span {
                      display: block;
                      border-color: ${colors.bg2};
                      border-top-style: solid;
                      border-top-width: 3px;
                      border-radius: 12px;
                    }

                    @media (max-width: 1199px) {
                    }

                    @media (max-width: 992px) {
                      top: 32%;
                    }

                    @media (max-width: 767px) {
                      display: none;
                    }
                  }

                  @media (max-width: 767px) {
                    width: 100%;
                  }
                }

                @media (max-width: 767px) {
                  flex-direction: column;
                  row-gap: 20px;
                }
              }

              .apply-button {
                margin-top: 30px;
                display: flex;
                justify-content: center;

                .apply {
                  color: white;
                  display: flex;
                  align-items: center;
                  color: #fff;
                  border: none;
                  border-radius: 8px;
                  padding: 8px 30px;
                  background-color: ${colors.bg1};
                  font-weight: 600;
                  font-size: 14px;
                  white-space: nowrap;
                  text-transform: uppercase;
                  text-decoration: none;

                  &:hover {
                    background-color: ${colors.bg2};
                  }
                }
              }

              @media (max-width: 767px) {
                font-size: 14px;
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }

    .instruction {
      padding: 60px 0;
      background-color: #f8f7fa;
      .container {
        .row {
          div {
            .main {
              /* border: 2px dashed ${colors.bg2}; */
              display: flex;
              flex-direction: column;

              .instruction-list {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .instruction-icon {
                  width: 24px !important;
                  height: 24px;
                }

                .instruction-item {
                  width: 100%;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  column-gap: 10px;
                  padding: 20px;
                  background: ${colors.text6};
                  border-radius: 8px;

                  @media (max-width: 767px) {
                    align-items: start;
                  }
                }
              }

              @media (max-width: 767px) {
                font-size: 14px;
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }

    .committee {
      padding: 24px 0;
      .container {
        .row {
          div {
            .main {
              display: flex;
              flex-direction: column;
              margin-top: 20px;

              .strategy-title {
                @media (max-width: 767px) {
                  font-size: 18px;
                }
              }

              .committee-list {
                .committee {
                  border-radius: 8px;
                  padding: 10px 15px;
                  box-shadow: 0 0px 7px rgba(0, 0, 0, 0.08);
                  margin-bottom: 15px;
                  background-color: #ffffff;

                  .name {
                    font-size: 16px;
                    font-weight: 600;
                    color: ${colors.black1};

                    @media (max-width: 767px) {
                      font-size: 15px;
                    }
                  }

                  .job {
                    @media (max-width: 767px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    a {
      color: ${colors.bg1};
      font-weight: 500;

      :hover {
        text-decoration: underline;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;
