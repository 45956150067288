import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import GoogleMap from "./GoogleMap";
import ContactForm from "../../components/contact/ContactForm";

import { Styles } from "./styles/contact.js";
import { useTranslation } from "react-i18next";
import Heading from "../../components/shared/Heading";
import SocialMediaComponent from "../../components/shared/SocialMediaComponent";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <Styles>
      <div className="main-wrapper contact-page">
        <BreadcrumbBox title="Contact Us" image="/assets/images/pre-uni.jpg" />
        <section className="contact-area">
          <Container>
            <Row className="mb-5">
              <Col md="4">
                <div className="contact-box-title">
                  <Heading tag="h4" title={"Contact Info"} />
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <Icon
                      icon="ph:map-pin-duotone"
                      fontSize={30}
                      color={colors.bg1}
                    />
                  </div>
                  <div className="box-content">
                    <Heading tag="h5" title={"Our Location"} />
                    <p>
                      <a
                        href={`https://yandex.ru/maps/?ll=69.291001,41.329240&pt=69.291001,41.329240&z=18&l=map`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          {t("1A, Chingiz Aytmatov Street, Tashkent")}
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <Icon
                      icon="ph:envelope-simple-duotone"
                      fontSize={30}
                      color={colors.bg1}
                    />
                  </div>
                  <div className="box-content">
                    <Heading tag="h5" title={"Email Address"} />
                    <p>
                      <a
                        href="mailto:info@pharmatechuni.uz"
                        rel="noopener noreferrer"
                      >
                        info@pharmatechuni.uz
                      </a>
                      <br />
                      <a
                        href="mailto:admission@pharmatechuni.uz"
                        rel="noopener noreferrer"
                      >
                        admission@pharmatechuni.uz
                      </a>
                      <br />
                      <a
                        href="mailto:recruitment@pharmatechuni.uz"
                        rel="noopener noreferrer"
                      >
                        recruitment@pharmatechuni.uz
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <Icon
                      icon="ph:phone-duotone"
                      fontSize={30}
                      color={colors.bg1}
                    />
                  </div>
                  <div className="box-content">
                    <Heading tag="h5" title={"Phone Number"} />
                    <p>
                      <a
                        href="tel:+998958338181"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +998 (95) 8338181
                      </a>
                      <br />
                      <a
                        href="tel:+998999508080"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +998 (99) 9508080
                      </a>
                      <br />
                      <a
                        href="tel:+998 71 2038181"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +998 (71) 2038181 ext (6)
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-icon-box d-flex">
                  <div className="icon">
                    <Icon
                      icon="ph:telegram-logo-duotone"
                      fontSize={30}
                      color={colors.bg1}
                    />
                  </div>
                  <div className="box-content">
                    <Heading tag="h5" title={"Telegram"} />
                    <p>
                      <a
                        href="https://t.me/ptuuz_bot"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        t.me/ptuuz_bot
                      </a>
                    </p>
                  </div>
                </div>

                <div className="contact-social">
                  <SocialMediaComponent />
                </div>
              </Col>
              <Col md="8">
                <ContactForm />
              </Col>
            </Row>
          </Container>
          <GoogleMap />
        </section>
      </div>
    </Styles>
  );
};

export default Contact;
