import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { config } from "../configs/repositoryConfig";
import Pages from "../routes/Pages";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(config.GAMeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: `${location?.pathname}${location?.pathname.search}`,
    });
  }, [location]);

  return (
    <div className="main-wrapper">
      <Header />
      <Pages />
      <Footer />
    </div>
  );
};

export default Layout;
