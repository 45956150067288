import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import remove from "lodash/remove";
import { v4 as uuidv4 } from "uuid";

import FormWithInputAndLabel from "../../components/shared/FormWithInputAndLabel";
import EmploymentInputsComponent from "../../components/apply/EmploymentInputsComponent";
import Button from "../../components/shared/Button";
import EducationInputsComponent from "../../components/apply/EducationInputsComponent";
import LanguageInputsComponent from "../../components/apply/LanguageInputsComponent";
import RefereeInputsComponent from "../../components/apply/RefereeInputsComponent";
import AlertComponent from "../../components/shared/AlertComponent";

import {
  CURRENCY_ENUM,
  DURATION_ENUM,
  LANGUAGE_LEVELS,
} from "../../utilities/constants";
import { useStatusContext } from "../../hooks/contextHooks/useStatusContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import userService from "../../services/userService";
import {
  cleanUpObject,
  getObjectPropertyName,
} from "../../utilities/objectUtils";

import { Styles } from "./styles/account.js";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import FileDropDownComponent from "../../components/shared/FileDropDownComponent";
import TooltipInformationComponent from "../../components/shared/TooltipInformationComponent";
import { appendToFormData } from "../../utilities/formDataUtils";
import moment from "moment";
import vacancyData from "../../data/vacancy/vacancy.json";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addYears } from "date-fns";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("First name field is required"),
  lastName: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Last name field is required"),
  dateOfBirth: yup
    .date()
    .max(addYears(new Date(), -16), "You must be at least 16 years old.")
    .required("Date of birth field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email field is required"),
  phoneNumber: yup.string().optional(),
  country: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Country field is required"),
  city: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("City field is required"),
  district: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("District field is required"),
  street: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Street field is required"),
  instruction: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("Home number field is required"),
  postalCode: yup.number().required("Postal code field is required"),
  salary: yup.number().required("Salary field is required"),
  qualifiedForPosition: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  workForPTU: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  visionOfPosition: yup
    .string()
    .min(2, "Minumum 2 characters are required")
    .required("The field is required to fill"),
  confirmation: yup.boolean().required(),
});

const EmployeeApplicationForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [onFetch, isLoading] = useBeManualFetcher();
  const formRef = useRef();
  const { id } = useParams();
  const { onErrorStatus } = useStatusContext();

  const [employments, setEmployments] = useState([{ id: uuidv4() }]);
  const [educations, setEducations] = useState([{ id: uuidv4() }]);
  const [trainings, setTrainings] = useState([{ id: uuidv4() }]);
  const [languages, setLanguages] = useState([{ id: uuidv4() }]);
  const [referees, setReferees] = useState([{ id: uuidv4() }]);
  const [currency, setCurrency] = useState(CURRENCY_ENUM.UZS);
  const [files, setFiles] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [passportCopy, setPassportCopy] = useState([]);

  const onFormSubmit = async (form) => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      email,
      phoneNumber,
      hearAboutUs,
      salary,
      workForPTU,
      qualifiedForPosition,
      visionOfPosition,
      confirmation,
      country,
      city,
      district,
      street,
      postalCode,
      instruction,
    } = form;

    let newFormData = new FormData();

    appendToFormData(newFormData, {
      firstName,
      lastName,
      dateOfBirth: moment(dateOfBirth).format("MM-DD-YYYY"),
      email,
      phoneNumber,
      hearAboutVacancy: hearAboutUs,
      amount: salary,
      currency,
      positionType: vacancyData.find((vacancy) => vacancy.id == id)
        .positionEnum,
      reasonAboutWorkingForPtu: workForPTU,
      positionOfQualified: qualifiedForPosition,
      visionOfPosition,
      country,
      city,
      district,
      street,
      postalCode,
      instruction,
    });

    if (employments?.[0]?.startDate?.length > 0) {
      employments?.map((employment, index) => {
        const filteredObject = cleanUpObject({
          [`[employmentPeriods][${index}][startDate]`]: employment?.startDate,
          [`[employmentPeriods][${index}][endDate]`]:
            employment?.duration === DURATION_ENUM.FINISHED
              ? employment?.endDate
              : undefined,
          [`[employmentPeriods][${index}][durationOfEmploymentPeriod]`]:
            employment?.duration,
          [`[employmentPeriods][${index}][organizationName]`]:
            employment?.employer,
          [`[employmentPeriods][${index}][positionInOrganization]`]:
            employment?.position,
          [`[employmentPeriods][${index}][summaryAboutResponsibilities]`]:
            employment?.duties,
          [`[employmentPeriods][${index}][supervisorPhoneNumber][name]`]:
            employment?.supervisor?.name,
          [`[employmentPeriods][${index}][supervisorPhoneNumber][phoneNumber]`]:
            employment?.supervisor?.phoneNumber,
        });
        return appendToFormData(newFormData, filteredObject);
      });
    }

    if (educations?.[0]?.startDate?.length > 0) {
      educations?.map((education, index) => {
        const filteredObject = cleanUpObject({
          [`[educations][${index}][startDate]`]: education?.startDate,
          [`[educations][${index}][endDate]`]:
            education?.duration === DURATION_ENUM.FINISHED
              ? education?.endDate
              : undefined,
          [`[educations][${index}][durationOfEducation]`]: education?.duration,
          [`[educations][${index}][nameOfInstitution]`]: education?.institution,
          [`[educations][${index}][qualification]`]: education?.qualification,
        });

        return appendToFormData(newFormData, filteredObject);
      });
    }

    if (trainings?.[0]?.startDate?.length > 0) {
      trainings?.map((training, index) => {
        const filteredObject = cleanUpObject({
          [`[practicalTrainings][${index}][startDate]`]: training?.startDate,
          [`[practicalTrainings][${index}][endDate]`]:
            training?.duration === DURATION_ENUM.FINISHED
              ? training?.endDate
              : undefined,
          [`[practicalTrainings][${index}][durationOfPracticalTraining]`]:
            training?.duration,
          [`[practicalTrainings][${index}][nameOfInstitution]`]:
            training?.institution,
          [`[practicalTrainings][${index}][qualificationOrOutcomes]`]:
            training?.qualification,
        });

        return appendToFormData(newFormData, filteredObject);
      });
    }

    if (languages?.[0]?.languageName?.length > 0) {
      languages?.map((language, index) => {
        return appendToFormData(newFormData, {
          [`[languages][${index}][language]`]: language?.languageName,
          [`[languages][${index}][languageLevel]`]: getObjectPropertyName(
            LANGUAGE_LEVELS,
            language?.level
          ),
        });
      });
    }

    if (referees?.[0]?.organization?.length > 0) {
      referees?.map((referee, index) => {
        return appendToFormData(newFormData, {
          [`[referees][${index}][fullName]`]: referee?.name,
          [`[referees][${index}][organization]`]: referee?.organization,
          [`[referees][${index}][position]`]: referee?.position,
          [`[referees][${index}][email]`]: referee?.email,
          [`[referees][${index}][phoneNumber]`]: referee?.phoneNumber,
        });
      });
    }

    appendToFormData(newFormData, {
      Passport: passportCopy?.[0],
      Photo: photo?.[0],
    });

    if (files?.length > 0) {
      files.forEach((file) => {
        newFormData.append(`files`, file);
      });
    }

    if (confirmation) {
      return await onFetch({
        action: async () => await userService.createEmployee(newFormData),
        onLoad: () => {
          setFiles([]);
          setPhoto([]);
          setPassportCopy([]);
          AlertComponent({
            title:
              "Thank you for your application and interest to work for PTU!",
            text: "We will consider your application and inform you regarding further steps of selection within 14 working days. ",
            icon: "success",
          });
          formRef.current.reset();
        },
      });
    }
    return onErrorStatus({
      title: "Validation Error",
      message:
        "You should check the checkbox to confirm that all provided were true and correct",
    });
  };

  const handleItemAdd = (state, setState) => {
    const copiedEmployments = [...state];

    copiedEmployments.push({ id: uuidv4() });
    setState(copiedEmployments);
  };

  const handleItemRemove = (id, state, setState) => {
    const copiedEmployments = [...state];

    remove(copiedEmployments, (job) => job.id === id);
    setState(copiedEmployments);
  };

  const handleUpdateEmployment = (id, obj) => {
    const copiedEmployments = [...employments];
    const index = copiedEmployments.findIndex((job) => job.id === id);

    copiedEmployments.splice(index, 1, obj);
    setEmployments(copiedEmployments);
  };

  const handleUpdateEducation = (id, obj) => {
    const copiedEducations = [...educations];
    const index = copiedEducations.findIndex((job) => job.id === id);

    copiedEducations.splice(index, 1, obj);
    setEducations(copiedEducations);
  };

  const handleUpdateTraining = (id, obj) => {
    const copiedTrainings = [...trainings];
    const index = copiedTrainings.findIndex((job) => job.id === id);

    copiedTrainings.splice(index, 1, obj);
    setTrainings(copiedTrainings);
  };

  const handleUpdateLanguage = (id, obj) => {
    const copiedLanguages = [...languages];
    const index = copiedLanguages.findIndex((job) => job.id === id);

    copiedLanguages.splice(index, 1, obj);
    setLanguages(copiedLanguages);
  };

  const handleUpdateReferee = (id, obj) => {
    const copiedEmployments = [...referees];
    const index = copiedEmployments.findIndex((job) => job.id === id);

    copiedEmployments.splice(index, 1, obj);
    setReferees(copiedEmployments);
  };

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox
          title={"Job Application Form"}
          image="/assets/images/banner-6.jpg"
        />
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Apply Now</h3>
                    <div className="registration-note">
                      <strong>Note:</strong> Please, fill all required input
                      fields to proceed. Required fields marked with * sign.
                    </div>
                  </div>
                  <form
                    id="form_registration"
                    className="form"
                    onSubmit={handleSubmit(onFormSubmit)}
                    ref={formRef}
                  >
                    <Row>
                      <Col md="6">
                        <FormWithInputAndLabel
                          label={"First Name"}
                          type="text"
                          placeholder="First name"
                          name="firstName"
                          id="firstName"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="6">
                        <FormWithInputAndLabel
                          label={"Last Name"}
                          type="text"
                          placeholder="Last name"
                          name="lastName"
                          id="lastName"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Date of Birth"}
                          type="date"
                          name="dateOfBirth"
                          id="dateOfBirth"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Email Address"}
                          type="email"
                          placeholder={"Email address"}
                          name="email"
                          id="email"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <FormWithInputAndLabel
                          label={"Phone Number"}
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          defaultValue={"+998"}
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"How did you hear about this vacancy?"}
                          type="text"
                          placeholder={
                            "Write shortly how did you hear about us"
                          }
                          name="hearAboutUs"
                          id="hearAboutUs"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Address<strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Country"}
                          type="text"
                          placeholder="Country"
                          name="country"
                          id="country"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"City"}
                          type="text"
                          placeholder="City"
                          name="city"
                          id="city"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"District"}
                          type="text"
                          placeholder="District"
                          name="district"
                          id="district"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Street"}
                          type="text"
                          placeholder="Street"
                          name="street"
                          id="street"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Home Number"}
                          type="text"
                          placeholder="Home Number"
                          name="instruction"
                          id="instruction"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4" lg="4">
                        <FormWithInputAndLabel
                          label={"Postal Code"}
                          type="number"
                          placeholder="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Employment<strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      {employments.map((employment) => (
                        <EmploymentInputsComponent
                          key={employment?.id}
                          id={employment?.id}
                          job={employment}
                          state={employments}
                          setState={setEmployments}
                          handleEmploymentRemove={handleItemRemove}
                          handleUpdateEmployment={handleUpdateEmployment}
                          showDeleteButton={employments?.length > 1}
                        />
                      ))}
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          text={"Add a row"}
                          className="outline"
                          iconName={"ph:plus-bold"}
                          onClick={() =>
                            handleItemAdd(employments, setEmployments)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Education
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      {educations.map((education) => (
                        <EducationInputsComponent
                          key={education?.id}
                          id={education?.id}
                          education={education}
                          state={educations}
                          setState={setEducations}
                          handleEducationRemove={handleItemRemove}
                          handleUpdateEducation={handleUpdateEducation}
                          showDeleteButton={educations?.length > 1}
                          isEducationSection={true}
                        />
                      ))}
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          text={"Add a row"}
                          className="outline"
                          iconName={"ph:plus-bold"}
                          onClick={() =>
                            handleItemAdd(educations, setEducations)
                          }
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Practical Training
                        </h6>
                      </Col>
                      {trainings.map((training) => (
                        <EducationInputsComponent
                          key={training?.id}
                          id={training?.id}
                          education={training}
                          state={trainings}
                          setState={setTrainings}
                          handleEducationRemove={handleItemRemove}
                          handleUpdateEducation={handleUpdateTraining}
                          showDeleteButton={trainings?.length > 1}
                        />
                      ))}
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          text={"Add a row"}
                          className="outline"
                          iconName={"ph:plus-bold"}
                          onClick={() => handleItemAdd(trainings, setTrainings)}
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3">
                          Languages
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      {languages.map((language) => (
                        <LanguageInputsComponent
                          key={language?.id}
                          id={language?.id}
                          language={language}
                          state={languages}
                          setState={setLanguages}
                          handleLanguageRemove={handleItemRemove}
                          handleUpdateLanguage={handleUpdateLanguage}
                          showDeleteButton={languages?.length > 1}
                        />
                      ))}
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          text={"Add a row"}
                          className="outline"
                          iconName={"ph:plus-bold"}
                          onClick={() => handleItemAdd(languages, setLanguages)}
                        />
                      </Col>
                      <Col md="12">
                        <h6 className="strategy-title mb-3 mt-3">
                          Referees
                          <strong className="text-danger">*</strong>
                        </h6>
                      </Col>
                      {referees.map((referee, index) => (
                        <RefereeInputsComponent
                          key={referee?.id}
                          id={referee?.id}
                          index={index}
                          referee={referee}
                          state={referees}
                          setState={setReferees}
                          handleRefereeRemove={handleItemRemove}
                          handleUpdateReferee={handleUpdateReferee}
                          showDeleteButton={referees?.length > 1}
                        />
                      ))}
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          text={"Add a row"}
                          className="outline"
                          iconName={"ph:plus-bold"}
                          onClick={() => handleItemAdd(referees, setReferees)}
                        />
                      </Col>
                      <Col md="6" lg="6">
                        <div className="form-control">
                          <label htmlFor={"passportCopy"}>
                            Scan version of your passport
                            <strong className="text-danger">*</strong>
                          </label>
                          <FileDropDownComponent
                            files={passportCopy}
                            setFiles={setPassportCopy}
                            buttonText="Select File"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-control">
                          <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor={"photo"}>
                              Personal photo (3.5x4.5)
                              <strong className="text-danger">*</strong>
                            </label>
                            <TooltipInformationComponent
                              componentToShow={
                                <div className="photo-information">
                                  <p className="mb-2">
                                    Official photo with white background is
                                    acceptable.
                                  </p>
                                </div>
                              }
                            />
                          </div>
                          <FileDropDownComponent
                            files={photo}
                            setFiles={setPhoto}
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="form-control">
                          <label htmlFor={"id"}>
                            Scan version of your diplomas (Bachelors/Masters
                            diploma)* with transcript
                            <strong className="text-danger">*</strong>
                          </label>
                          <FileDropDownComponent
                            files={files}
                            setFiles={setFiles}
                            multiple
                            buttonText={"Select files"}
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <FormWithInputAndLabel
                          label={"Expected Salary"}
                          type="number"
                          name="salary"
                          id="salary"
                          min={0}
                          register={register}
                          errors={errors}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <div className="form-control">
                          <label htmlFor="currency">{"Currency"}</label>
                          <select
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            {Object.values(CURRENCY_ENUM).map((currency) => (
                              <option value={currency} key={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"Why do you want to work for PTU?"}
                          name="workForPTU"
                          id="workForPTU"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={
                            "Why do you think you are qualified for this position?"
                          }
                          name="qualifiedForPosition"
                          id="qualifiedForPosition"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <FormWithInputAndLabel
                          label={"What is your vision of this position?"}
                          name="visionOfPosition"
                          id="visionOfPosition"
                          register={register}
                          errors={errors}
                          isTextArea
                          required
                        />
                      </Col>
                      <Col md="12">
                        <div className={`custom-checkbox mb-4`}>
                          <label
                            htmlFor={"confirmation"}
                            className="font-weight-bold"
                          >
                            I hereby declare that the information provided is
                            true and correct
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="checkbox"
                            name="confirmation"
                            id="confirmation"
                            className="ml-2"
                            style={{ width: "20px" }}
                            {...register("confirmation", {
                              required: "Input field is required to fill*",
                            })}
                          />
                          {errors?.["confirmation"]?.type === "required" && (
                            <p className="invalid">
                              {errors?.["confirmation"]?.message}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        text={"Submit"}
                        className="px-5 py-3"
                        type={"submit"}
                        isLoading={isLoading}
                        eventName={"employee_app_submit"}
                      />
                    </div>
                  </form>
                  <div className="have_account-btn text-center">
                    <p>
                      Are you a student?{" "}
                      <Link to="/apply-students">Apply here</Link>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default EmployeeApplicationForm;
